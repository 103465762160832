import { api } from '../config/api'

export const getConfiguration = key => api.get(`/configuration/${key}`)

export const updateConfiguration = (key, body) => api.put(`/configuration/${key}`, body)

export const updatePopupHomeImg = (keyValue, body) => api.put(`/backoffice/popup_config/${keyValue}/image`, body)

export const updatePopupHomeStatus = (keyValue, body) => api.put(`/backoffice/popup_config/${keyValue}/show-status`, body)

export const getPopupHomeData = (keyValue) => api.get(`/popup_config/${keyValue}`)
