export default {
  btnSave: 'Guardar',
  cancel: 'Cancelar',
  close: 'Cerrar',
  btnConfirm: 'Confirmar',
  confirm: '¿Está seguro de realizar esta acción?',
  deltaDeliveryDays: 'Días hábiles de entrega',
  deltaDeliveryDaysSuccess: 'Días hábiles de entrega se editó correctamente',
  deltaDeliveryDaysError: 'Ocurrió un error al editar los días hábiles de entrega',
  customizationUnderMinExtra: 'Costo adicional para personalización por debajo de los mínimos',
  customizationUnderMinExtraCost: 'Costo en pesos (CLP)',
  customizationUnderMinSuccess: 'El costo fue editado correctamente',
  customizationUnderMinError: 'Ocurrió un error al editar el costo por debajo de los mínimos',
  deliveryPickupPrice: 'Tope en Pesos (CLP)',
  deliverySectionTitle: 'Tope de entrega',
  deliveryDiscountSectionTitle: 'Tope de descuento en entrega',
  deliverySuccess: 'El tope de entrega fue editado correctamente',
  deliveryError: 'Ocurrió un error al editar el tope de entrega',
  deliveryDiscountSuccess: 'El tope de descuento en entrega fue editado correctamente',
  deliveryDiscountError: 'Ocurrió un error al editar el tope de descuento en entrega',
  deliveryAdvanceEmails: 'Emails de adelanto de entrega. Los emails se deben separar con punto y coma.',
  deliveryAdvanceEmailsSuccess: 'Se editaron los mail de notificacion correctamente',
  deliveryAdvanceEmailsError: 'Ocurrió un error al editar los mails de notificación',
  dollarError: 'Ocurrió un error al editar el precio del Dólar',
  dollarPointsError: 'Ocurrió un error al editar el precio del Dólar para puntos',
  dollarPrice: 'Tipo de cambio (USD)',
  dollarPointsPrice: 'Dólar para puntos (USD)',
  dollarSectionTitle: 'Dólar',
  dollarSuccess: 'El precio del dólar fue editado correctamente',
  dollarPointsSuccess: 'El precio del Dólar para puntos fue editado correctamente',
  pickupSectionTitle: 'Tope de pickup',
  notificationsSectionTitle: 'Notificaciones',
  pickupSuccess: 'El tope de pickup fue editado correctamente',
  authorizedDesktopBanners: 'Banners Desktop estando logueado',
  unauthorizedDesktopBanners: 'Banners Desktop sin estar logueado',
  authorizedMobileBanners: 'Banners Mobile estando logueado',
  unauthorizedMobileBanners: 'Banners Mobile sin estar logueado',
  hyperlink: 'hipervínculo',
  desktopBannersSpan:
    'Cargá hasta 5 banners promocionales. Para una mejor visualización se recomiendan las dimensiones 1200x250px y el peso máximo de 500KB.',
  mobileBannersSpan:
    'Cargá hasta 5 banners promocionales. Para una mejor visualización se recomiendan las dimensiones 400x180px y el peso máximo de 150KB.',
  orderRequiredUniqueError: 'Los número de orden son requeridos y deben ser únicos',
  descriptionField: 'Descripción',
  orderField: 'Orden',
  title: 'Título',
  hyperlinkType: 'Tipo de hipervínculo',
  keyWordForSearch: 'Palabra clave para la búsqueda',
  uploadBannersButton: 'Guardar',
  publishBannersSuccess: 'Los banners fueron guardados con éxito',
  points: 'Puntos',
  pointsPerArs: 'Puntos por peso (CLP)',
  arsToPointsSuccess: 'Los puntos por peso fueron editados correctamente',
  arsToPointsError: 'Ocurrió un error al editar los puntos por peso',
  success: {
    imagePopupUpdated: 'Imagen actualizada',
    updateStatus: 'Actualizado correctamente',
  },
  failure: {
    publishBanners: 'Hubo un error al guardar los banners',
    uploadBanner: 'Hubo un error al guardar el banner',
    fileTypeErrorUpload: 'Tipo de archivo no admitido',
    mobileSizeErrorUpload: 'Los banners mobile deben pesar como máximo 300KB',
    sizeErrorUpload: 'Los banners deben pesar como máximo {num}KB',
    uploadPopup: 'Hubo un error al guardar la imagen',
    updateStatus: 'Hubo un error al actualizar los datos',
    getPopupConfig: 'Erro al obtener los dados de Popup Home'
  },
  errors: {
    title: 'Error',
    required: 'Debes agregar como mínimo 1 imagen',
    desktopRequired: 'Las imágenes desktop son obligatorias',
    mobileRequired: 'Las imágenes mobile son obligatorias',
    orderRequired: 'Los números de orden son obligatorios',
    orderIsUnique: 'Los números de orden no pueden estar repetidos',
    descriptionRequired: 'Las descripciones son obligatorias',
    haveAllOrders: 'Los números de orden no pueden estar salteados',
    urlFormat: 'Formato de url inválido (ej: https://www.zecat.cl)',
    noWhiteSpacesAllowed: 'No se permiten espacios en blanco'
  }
}
